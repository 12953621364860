import PageSpinner from '@/components/PageSpinner'
import type { CommentViewModel } from '@/services/types'
import { useToggleURLQueryParams } from '@/utils/useToogleQueryParam'
import Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Group, Image, Layer, Line, Stage } from 'react-konva'
import useImage from 'use-image'
import {
  useFitImageToStage,
  useKeyboardEvents,
  useReviewAreaWidth,
  useZoom,
} from '../../../components/EssayArea/EssayReviewArea.hooks'
import { EssayImageShortcut } from './EssayImageShortcut'

const lineOpacities = {
  default: 0.3,
  hover: 0.6,
}

const centralizeCanvasStyle = {
  padding: 0,
  margin: 'auto',
  display: 'block',
}

export type LineProps = {
  id?: string
  lineHeight?: number | null
  startPosX?: number | null
  startPosY?: number | null
  endPosX?: number | null
  endPosY?: number | null
}

type EssayImageReviewProps = {
  imageUrl: string
  lineHeight?: number
  lines?: LineProps[] | null
  isViewOnlyMode: boolean
  onAddNewLine?: (newLine: LineProps) => void
  onClickLine?: (id: string) => void
}

export type EssayImageReviewRef = {
  resetDraftLines: () => void
}

Konva.hitOnDragEnabled = true

export const EssayImageReview = forwardRef((props: EssayImageReviewProps, ref) => {
  const { imageUrl, lines, isViewOnlyMode, lineHeight, onAddNewLine, onClickLine } = props
  const dimensions = useReviewAreaWidth()
  const [image, imageState] = useImage(imageUrl)
  const stageRef = useRef<Konva.Stage>(null)
  const stage = stageRef?.current

  const { hasURLQueryParams } = useToggleURLQueryParams()
  const isCommentModeActive = hasURLQueryParams(['isCommentModeActive'])

  const { handleWheel, handleTouchZoomMove, handleTouchZoomEnd } = useZoom(stage)

  // Keyboard events to allow comments creation
  useKeyboardEvents(stage)

  // Resize and center image to fit stage, especially on mobile
  useFitImageToStage(stage, image)

  // Turn on/off comment mode
  useEffect(() => {
    if (!stage) return
    if (isCommentModeActive) stage.draggable(false)
    else stage.draggable(true)
  }, [stage, isCommentModeActive])

  // Adding new line
  const [draftLine, setDraftLine] = useState<LineProps>()
  const [isDrawing, setIsDrawing] = useState(false)

  const handleTouchStart = (e: KonvaEventObject<TouchEvent>) => {
    if (!isCommentModeActive || isViewOnlyMode) return
    setIsDrawing(true)
    setDraftLine(kovanPosToDraftLine(e))
  }

  const handleTouchMove = (e: KonvaEventObject<TouchEvent>) => {
    if (!isDrawing || !draftLine || isViewOnlyMode) return handleTouchZoomMove(e)
    setDraftLine(kovanPosToDraftLine(e, draftLine))
  }

  const handleTouchEnd = () => {
    if (!isCommentModeActive) return handleTouchZoomEnd()
    setIsDrawing(false)
    onAddNewLine?.({ ...draftLine, lineHeight })
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (!isCommentModeActive || isViewOnlyMode) return
    setIsDrawing(true)
    setDraftLine(kovanPosToDraftLine(e))
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!isDrawing || !draftLine || isViewOnlyMode) return
    setDraftLine(kovanPosToDraftLine(e, draftLine))
  }

  const handleMouseUp = () => {
    if (!draftLine || isViewOnlyMode) return
    setIsDrawing(false)
    onAddNewLine?.({ ...draftLine, lineHeight })
  }

  // Saved lines
  const handleLineClick = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true // avoid select a line and start drawing
    onClickLine?.(e.currentTarget.id())
  }

  // Call externally to remove temporary comment lines after creation
  useImperativeHandle(
    ref,
    (): EssayImageReviewRef => ({
      resetDraftLines: () => setDraftLine(undefined),
    }),
  )

  return (
    <>
      {!isViewOnlyMode && <EssayImageShortcut />}
      {imageState === 'loading' && <PageSpinner />}
      <Stage
        ref={stageRef}
        width={dimensions.width}
        height={dimensions.height}
        style={centralizeCanvasStyle}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onWheel={handleWheel}>
        <Layer>
          <Group onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <Image image={image} width={image?.naturalWidth} height={image?.naturalHeight} />
            <Line
              points={getPoints(draftLine)}
              stroke="blue"
              opacity={lineOpacities.default}
              strokeWidth={lineHeight}
              lineCap="round"
            />
          </Group>
          {lines?.map((line, i) => (
            <Line
              key={i}
              id={line.id}
              points={getPoints(line)}
              onClick={handleLineClick}
              onTap={handleLineClick}
              opacity={lineOpacities.default}
              stroke="lime"
              strokeWidth={line.lineHeight || 10}
              lineCap="round"
              onMouseEnter={(e) => e.currentTarget.opacity(lineOpacities.hover)}
              onMouseLeave={(e) => e.currentTarget.opacity(lineOpacities.default)}
            />
          ))}
        </Layer>
      </Stage>
    </>
  )
})

const getPoints = (line?: CommentViewModel): number[] => {
  if (!line) return []
  return [line?.startPosX || 0, line?.startPosY || 0, line?.endPosX || 0, line?.endPosY || 0]
}

const kovanPosToDraftLine = (e: KonvaEventObject<MouseEvent | TouchEvent>, existingLine?: LineProps): LineProps | undefined => {
  const stage = e.target.getStage()
  if (!stage) return

  const pointerPosition = stage.getPointerPosition()
  if (!pointerPosition) return

  const scale = stage.scaleX() // Assuming uniform scaling (scaleX === scaleY)
  const stagePosition = stage.position()

  // Calculate the actual position considering the scale and drag
  const actualX = (pointerPosition.x - stagePosition.x) / scale
  const actualY = (pointerPosition.y - stagePosition.y) / scale

  const roundedX = Math.round(actualX)
  const roundedY = Math.round(actualY)

  if (existingLine) return { ...existingLine, endPosX: roundedX, endPosY: roundedY }
  return { startPosX: roundedX, startPosY: roundedY, endPosX: roundedX, endPosY: roundedY }
}
