/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  DeleteApiV1SharedAddressSchoolIdParams,
  ProblemDetails,
  SchoolAddressViewModel
} from './types'




/**
 * @summary Removes the address of the school
 */
export const deleteApiV1SharedAddressSchoolId = (
    id: string,
    params?: DeleteApiV1SharedAddressSchoolIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.delete(
      `/api/v1/shared/address/school/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiV1SharedAddressSchoolIdMutationOptions = <TData = Awaited<ReturnType<typeof deleteApiV1SharedAddressSchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;params?: DeleteApiV1SharedAddressSchoolIdParams}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteApiV1SharedAddressSchoolId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1SharedAddressSchoolId>>, {id: string;params?: DeleteApiV1SharedAddressSchoolIdParams}> = (props) => {
          const {id,params} = props ?? {};

          return  deleteApiV1SharedAddressSchoolId(id,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;params?: DeleteApiV1SharedAddressSchoolIdParams}, TContext>}

    export type DeleteApiV1SharedAddressSchoolIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1SharedAddressSchoolId>>>
    
    export type DeleteApiV1SharedAddressSchoolIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes the address of the school
 */
export const useDeleteApiV1SharedAddressSchoolId = <TData = Awaited<ReturnType<typeof deleteApiV1SharedAddressSchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;params?: DeleteApiV1SharedAddressSchoolIdParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {id: string;params?: DeleteApiV1SharedAddressSchoolIdParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1SharedAddressSchoolIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Adds or updates the school's address
 */
export const putApiV1SharedAddressSchoolSchoolId = (
    schoolId: string,
    schoolAddressViewModel: SchoolAddressViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/shared/address/school/${schoolId}`,
      schoolAddressViewModel,options
    );
  }



export const getPutApiV1SharedAddressSchoolSchoolIdMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1SharedAddressSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: SchoolAddressViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1SharedAddressSchoolSchoolId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1SharedAddressSchoolSchoolId>>, {schoolId: string;data: SchoolAddressViewModel}> = (props) => {
          const {schoolId,data} = props ?? {};

          return  putApiV1SharedAddressSchoolSchoolId(schoolId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;data: SchoolAddressViewModel}, TContext>}

    export type PutApiV1SharedAddressSchoolSchoolIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1SharedAddressSchoolSchoolId>>>
    export type PutApiV1SharedAddressSchoolSchoolIdMutationBody = SchoolAddressViewModel
    export type PutApiV1SharedAddressSchoolSchoolIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds or updates the school's address
 */
export const usePutApiV1SharedAddressSchoolSchoolId = <TData = Awaited<ReturnType<typeof putApiV1SharedAddressSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: SchoolAddressViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;data: SchoolAddressViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SharedAddressSchoolSchoolIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    