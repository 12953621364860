/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */

export type BalanceTransactionSource = typeof BalanceTransactionSource[keyof typeof BalanceTransactionSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceTransactionSource = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;
