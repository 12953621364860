import { ImageUploadArea } from '@/components/ImageUploadArea/ImageUploadArea.tsx'
import { usePostApiV1Theme } from '@/services/theme.ts'
import { usePostApiV1ThemeSupportMaterial } from '@/services/theme.ts'
import type { NewSupportMaterialViewModel } from '@/services/types'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Headbar from '../../../components/Headbar'
import StepsImage from '../../../components/StepsImage'
import useCreateThemeStore from '../../../store/useCreateThemeStore'
import { ThemeMaterialUpload } from './ThemeMaterialUpload'

let uploadCounter = 0
let newThemeID = ''
const maxCharacteres = 500

const validationSchema = yup.object({
  image: yup.string(),
  subject: yup.string().min(5).max(255).required(),
  description: yup.string().max(500),
})
type Form = yup.InferType<typeof validationSchema>

// type SuggestedTheme = {
//   id: string
//   title: string
//   description: string
// }

function CreateThemeStep1(props) {
  const { handlingNextStep } = props
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const { onOpen: openPopover, onClose: closePopover, isOpen: isPopoverOpen } = useDisclosure()
  const { clearThemeData, themeData } = useCreateThemeStore()
  //const { loggedUser } = useLoggedUserStore()
  //const [suggestedThemes, setSuggestedThemes] = useState<SuggestedTheme[]>([])
  const [supportMaterialFiles, setSupportMaterialFiles] = useState<NewSupportMaterialViewModel[]>([])
  //const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const postApiV1Theme = usePostApiV1Theme({
    mutation: {
      onSuccess: (result) => {
        newThemeID = (result.data as unknown as { message: string }).message
        clearThemeData(newThemeID)
        if (!supportMaterialFiles.length) return handlingNextStep()

        uploadCounter = supportMaterialFiles.length
        for (const item of supportMaterialFiles) {
          item.themeId = newThemeID
          postApiV1ThemeSupportMaterial.mutate({ data: item })
        }
      },
    },
  })

  const postApiV1ThemeSupportMaterial = usePostApiV1ThemeSupportMaterial({
    mutation: {
      onSuccess: () => {
        uploadCounter--
        if (uploadCounter !== 0) return
        handlingNextStep()
      },
    },
  })

  // const { mutate: postApiV1ThemeUsage, isPending: assistantPending } = usePostApiV1AssistantThemeAssistantUsage({
  //   mutation: {
  //     onSuccess: (response: AxiosResponse) => {
  //       toast.success('Temas gerados com sucesso!')
  //
  //       const themes: SuggestedTheme[] = response.data.message.response
  //         .split('\n')
  //         .filter((line) => line.trim() !== '')
  //         .map((text, index) => {
  //           const [title, ...descriptionParts] = text.split(':')
  //           return {
  //             id: `${Date.now()}-${index}`,
  //             title: title
  //               .replace(/^\d+\.\s/, '')
  //               .replace(/\*\*/g, '')
  //               .trim(),
  //             description: descriptionParts.join(':').trim(),
  //           }
  //         })
  //
  //       setSuggestedThemes((prevThemes) => [...themes, ...prevThemes])
  //
  //       onClose()
  //       openPopover()
  //
  //       setIsButtonDisabled(true)
  //     },
  //   },
  // })

  // const handleConfirm = () => {
  //   postApiV1ThemeUsage({ data: { schoolId: loggedUser.schoolId } })
  // }

  const handleImageUpload = (image: string) => setValue('image', image)
  const submit = async (form: Form) => {
    const requestBody = {
      ...form,
      schoolId: themeData.schoolId,
    }

    postApiV1Theme.mutate({ data: requestBody })
  }

  const handleSupportMaterialUpload = (files: NewSupportMaterialViewModel[]) => setSupportMaterialFiles(files)

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-temas'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar tema
        </Heading>
        <VStack w={'100%'}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(submit)} noValidate>
            <HStack justifyContent={'space-between'}>
              <StepsImage text={'Sobre o tema'} number={1} />
              {/*<HStack>*/}
              {/*  <Button*/}
              {/*    variant={'primary'}*/}
              {/*    backgroundColor={'#0A41D6 !important'}*/}
              {/*    size={'sm'}*/}
              {/*    onClick={onOpen}*/}
              {/*    isDisabled={isButtonDisabled}>*/}
              {/*    Gerar por IA*/}
              {/*  </Button>*/}
              {/*  {suggestedThemes.length > 0 && (*/}
              {/*    <ThemeAiAssistantPopover*/}
              {/*      closePopover={closePopover}*/}
              {/*      isPopoverOpen={isPopoverOpen}*/}
              {/*      openPopover={openPopover}*/}
              {/*      suggestedThemes={suggestedThemes}*/}
              {/*      setValue={setValue}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</HStack>*/}
            </HStack>

            {/*<ThemeAIAssistantModal*/}
            {/*  assistantPending={assistantPending}*/}
            {/*  onClose={onClose}*/}
            {/*  isOpen={isOpen}*/}
            {/*  handleConfirm={handleConfirm}*/}
            {/*/>*/}

            <Grid width={'100%'} gap={'32px'} mt={'32px'}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.image && !!errors.image.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Imagem do tema</FormLabel>
                  <ImageUploadArea onUpload={handleImageUpload} />
                  <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.subject && !!errors.subject.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Título*</FormLabel>
                  <Input
                    id={'subject'}
                    {...register('subject')}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                  />
                  <FormErrorMessage>{errors.subject?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.description && !!errors.description.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Descrição</FormLabel>
                  <Textarea
                    resize="vertical"
                    id={'description'}
                    {...register('description')}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                  />
                  <Text
                    fontSize="sm"
                    position="absolute"
                    zIndex={1}
                    bottom={1}
                    right={4}
                    color="gray.400"
                    fontFamily={'Public Sans'}>
                    {watch('description')?.length ?? 0}/{maxCharacteres}
                  </Text>
                  <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Material de apoio</FormLabel>
                  <ThemeMaterialUpload onChange={handleSupportMaterialUpload} />
                </FormControl>
              </GridItem>
            </Grid>

            <Box display="flex" justifyContent="end" w={'100%'} gap={3} my={2}>
              <Button
                flex={0.5}
                type="submit"
                variant="primary"
                isLoading={postApiV1Theme.isPending || postApiV1ThemeSupportMaterial.isPending}>
                Finalizar
              </Button>
            </Box>
          </form>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateThemeStep1
