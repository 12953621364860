import FormStepNotSelected from '@/components/FormStepNotSelected'
import Headbar from '@/components/Headbar'
import StepsImage from '@/components/StepsImage'
import { usePostApiV1TeacherMonitor } from '@/services/teacher'
import { inputPhoneMask } from '@/utils/inputMasks'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { type SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { validatePhone } from 'validations-br'
import * as yup from 'yup'

interface CreateMonitorStep1Props {
  handlingNextStep: (result) => void
}

const schema = yup.object({
  name: yup.string().required().min(3),
  email: yup.string().required().email(),
  phoneNumber: yup
    .string()
    .nullable()
    .test('phone', 'Telefone inválido', (value) => {
      return !value || validatePhone(value)
    }),
})

interface MonitorFormValues {
  name: string
  email: string
  phoneNumber: string
}

function CreateMonitorStep1({ handlingNextStep }: CreateMonitorStep1Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
    },
  })

  const { mutate: createMonitor, isPending: isCreatingMonitor } = usePostApiV1TeacherMonitor({
    mutation: {
      onSuccess: (result) => {
        handlingNextStep(result)
      },
      onError: (error) => {
        console.error('Erro ao criar o monitor:', error)
      },
    },
  })

  const onSubmit: SubmitHandler<MonitorFormValues> = (data) => {
    createMonitor({ data })
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-monitores'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Heading color="brand.200" alignSelf={'start'}>
            Criar monitor
          </Heading>
          <VStack w={'100%'} height={'100%'} mt={'12px'} alignItems={'start'}>
            <StepsImage text={'Sobre o monitor'} number={1} />

            <FormControl isInvalid={!!errors.name} mt={4}>
              <FormLabel className="publicsans-bold-nevada-16px">Nome*</FormLabel>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'} borderColor={'#d7e3fb'} {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email} mt={4}>
              <FormLabel className="publicsans-bold-nevada-16px">Email*</FormLabel>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'} borderColor={'#d7e3fb'} {...register('email')} />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phoneNumber} mt={4}>
              <FormLabel className="publicsans-bold-nevada-16px">Telefone</FormLabel>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                {...register('phoneNumber')}
                as={InputMask}
                mask={inputPhoneMask}
              />
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>

            <Box mt={5} className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'} />
            </Box>
          </VStack>

          <Box display="flex" justifyContent="end" w={'100%'} gap={3} my={6}>
            <Button flex={0.5} type="submit" variant="primary" isLoading={isCreatingMonitor}>
              Continuar
            </Button>
          </Box>
        </form>
      </VStack>
    </VStack>
  )
}

export default CreateMonitorStep1
