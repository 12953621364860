/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */

export interface NewCountryViewModel {
  /** @nullable */
  name?: string | null;
  /** @nullable */
  currencyCode?: string | null;
}
