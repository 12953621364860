import '../../../App.css'
import fontsUtils from '@/theme/foundations/FontsUtils'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Link as ChakraLink,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'

export default function LandingPage() {
  const [selectedTab, setSelectedTab] = useState(0)

  const menuItems = [
    { label: 'Sobre', id: 'sobre' },
    { label: 'Planos', id: 'planos' },
    { label: 'FAQ', id: 'faq' },
    { label: 'Contato', id: 'contato' },
  ]
  const tabImages = ['/img/lp/video-temp.png', '/img/lp/video-tempo.png', '/img/lp/video-temp.png', '/img/lp/video-tempo.png']

  const faqData = [
    {
      title: 'O que é o Correção Online e como ele pode me beneficiar?',
      content:
        'O Correção Online não é apenas uma plataforma, é a solução definitiva para professores que desejam otimizar o processo de solicitação, recebimento, correção e avaliação de redações. Transforme sua maneira de ensinar e interagir com seus alunos através da nossa tecnologia inovadora.',
    },
    {
      title: 'O Correção Online substitui o papel do professor?',
      content:
        'O Correção Online oferece uma solução complementar ao trabalho do professor, ajudando a otimizar a correção e fornecendo feedback ágil, mas não substitui a função do professor.',
    },
    {
      title: 'Como posso cadastrar turmas e alunos de forma prática?',
      content:
        'A plataforma oferece uma interface simples e intuitiva para cadastrar turmas e alunos, com opções para importar dados em massa e organizar suas atividades de forma eficiente.',
    },
    {
      title: 'Sou professor particular. O Correção Online é para mim?',
      content:
        'Sim! O Correção Online foi pensado para atender tanto escolas quanto professores particulares. A plataforma oferece flexibilidade e recursos para otimizar sua rotina de correção.',
    },
    {
      title: 'É seguro confiar meus documentos ao Correção Online?',
      content:
        'Sim, a plataforma adota as melhores práticas de segurança, com criptografia de ponta e sistemas de proteção para garantir que seus documentos e dados pessoais estejam seguros.',
    },
    {
      title: 'O Correção Online pode detectar plágios?',
      content:
        'Sim, a plataforma possui uma ferramenta de detecção de plágio integrada, permitindo que você verifique a autenticidade das redações enviadas pelos alunos.',
    },
  ]

  const handleTabChange = (index: number) => {
    setSelectedTab(index)
  }

  return (
    <>
      <Box as="header" position={'fixed'} width={'100vw'} bg={'#fff'} zIndex={2}>
        <Container maxW="container.lg" p={0}>
          <Flex as="nav" align="center" justify="space-between" px={5} py={5} position="relative" gap={4}>
            {/* Logo */}
            <Image src="/img/lp/logo.svg" alt="Logo Correção Online" />

            {/* Links de Navegação */}
            <HStack as="ul" spacing={6} listStyleType="none" align="center" display={{ base: 'none', md: 'flex' }}>
              {menuItems.map(({ label, id }) => (
                <Box
                  as="li"
                  key={label}
                  cursor="pointer"
                  fontSize="md"
                  color="gray.600"
                  transition="all .1s ease-in-out"
                  fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                  _hover={{
                    transform: 'scale(1.05)',
                  }}>
                  <ScrollLink to={id} smooth={true} duration={500} offset={-70} spy={true}>
                    {label}
                  </ScrollLink>
                </Box>
              ))}
            </HStack>
            <HStack justify="flex-end">
              <Button
                as={Link}
                to="/cadastro"
                display={{ base: 'none', sm: 'flex' }}
                backgroundColor="transparent"
                fontWeight="medium"
                fontSize="md"
                lineHeight="230%"
                transition="all 0s"
                color="gray.600"
                _hover={{ color: 'brand.200', textDecoration: 'underline' }}
                padding={5}>
                Cadastre-se
              </Button>
              {/* Botão de Login */}
              <ChakraLink
                as={Link}
                to="/login"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={3}
                border="0.5px solid"
                borderColor="brand.200"
                borderRadius="8px"
                color="brand.200"
                fontWeight="bold"
                textDecoration="none"
                transition="all 0.2s ease-in-out"
                gap={1}
                // biome-ignore lint/a11y/useSemanticElements:
                role="group"
                _hover={{
                  backgroundColor: 'brand.200',
                  color: '#FFF',
                }}>
                <Button
                  backgroundColor="transparent"
                  fontWeight="medium"
                  fontSize="sm"
                  lineHeight="230%"
                  transition="all 0s"
                  _hover={{
                    backgroundColor: 'transparent',
                  }}
                  padding={0}
                  color="inherit">
                  Entrar
                </Button>
                <Image
                  transition="all 0.2s ease-in-out"
                  src="/img/ic-login.svg"
                  alt="Entrar Icon"
                  _groupHover={{
                    filter: 'brightness(0) invert(1)',
                  }}
                />
              </ChakraLink>
            </HStack>
          </Flex>
        </Container>
      </Box>

      <Box as="section" bg="#fff" pt={32} pb={0} overflowX={'hidden'}>
        <Container maxW="container.lg" p={0}>
          <Flex align="center" direction="row" px={5} gap={5}>
            {/* Texto */}
            <Box textAlign="left" flex={2} py={5}>
              <Heading
                as="h1"
                fontSize={{ base: '3xl', sm: '3xl', md: '3xl', lg: '5xl' }}
                mb={{ md: '5', base: '2' }}
                fontWeight="bold"
                color="brand.200"
                lineHeight="1.4"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                zIndex={1}>
                Menos tempo{' '}
                <Box as="span" bgImage="url('/img/lp/ondulado.svg')" bgPosition="bottom" bgRepeat="no-repeat" bgSize="contain">
                  corrigindo <br />
                </Box>{' '}
                mais tempo{' '}
                <Box as="span" color="#FFF" bg="brand.200">
                  educando
                </Box>
                .
              </Heading>

              <Text fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily} fontSize="2xl" color="#6D6D6D">
                Corrija redações com rapidez e eficiência!
              </Text>
            </Box>

            {/* Imagem */}
            <Box display={{ base: 'none', sm: 'flex' }} flex={1} mr={{ sm: '-60px', md: '0' }}>
              <Image src="/img/lp/ilustracao.svg" alt="Correção Online" objectFit="contain" />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box as="section" bg="brand.200" py={8} color="white" position={'relative'} mt={{ md: '-.5rem' }}>
        <Container maxW="container.lg" px={5}>
          <Flex
            align={{ md: 'center', base: 'flex-start' }}
            justify="space-between"
            gap={6}
            direction={{ base: 'column', md: 'row' }}>
            {/* Lista de Benefícios */}
            <List fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily} spacing={4} styleType="none" fontSize={'xl'}>
              {[
                'Revisões até 80% mais rápidas',
                'Redução de custo com papéis',
                'Aumento de qualidade nas revisões',
                'Suporte de inteligência artificial',
              ].map((benefit, index) => (
                <ListItem key={index} display="flex" alignItems="center">
                  <Image src="/img/lp/accept.svg" alt="Ícone de Aceite" mr={4} />
                  {benefit}
                </ListItem>
              ))}
            </List>

            {/* Imagem Central */}
            <Box className="image" my={{ base: 8, md: 0 }} display={{ base: 'none', lg: 'flex' }}>
              <Image src="/img/lp/arrow.svg" alt="Central Image" maxW="500px" objectFit="contain" />
            </Box>

            {/* CTA */}
            <Box textAlign="start">
              <Text
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                fontSize="md"
                fontWeight="medium"
                color="#FBFF00"
                mb={2}>
                Grátis e sem cartão de crédito
              </Text>
              <Button
                as="a"
                href="/cadastro"
                bg="#13FF69"
                color="#062989"
                border="2px solid #008CFF"
                px={8}
                py={8}
                fontSize="xl"
                fontWeight="bold"
                display="flex"
                alignItems="center"
                gap={7}
                lineHeight="unset"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                _hover={{ bg: '#0FCF5E' }}>
                Cadastre-se agora
                <Image src="/img/lp/arrow2.svg" alt="Seta" />
              </Button>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box as="section" pt={{ md: '6.6875rem', base: '10.93vw' }} bg="white">
        <Container
          maxW="container.lg"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column">
          <Box
            position="absolute"
            top={{ md: '-5rem', base: '-5vw' }}
            width={0}
            visibility={'hidden'}
            height={0}
            id="sobre"
            bg="red">
            ref menu
          </Box>
          {/* Título e Texto */}
          <VStack spacing={{ md: 4, base: 8 }} align="center" textAlign="center">
            <Heading
              as="h2"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              fontWeight="extrabold"
              size="xl"
              color="brand.200"
              w={{ base: '100%', md: '35.375rem' }}>
              Mais de 600 escolas digitalizadas no Brasil e no mundo
            </Heading>
            <Text
              fontSize="lg"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              color="#6B6B79"
              w={{ base: '100%', md: '41.875rem' }}>
              Nosso objetivo é ajudar educadores e escolas a corrigir com mais qualidade e eficiência, liberando mais tempo para
              focar nos alunos.
            </Text>
          </VStack>

          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'center'}
            gap={{ md: 8, base: '.625rem' }}
            mt={{ md: 24, base: '16.27vw' }}
            display="none">
            {/* Card 1 */}
            <Box
              bg="#F7F7F7"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              color="white"
              p={4}
              display="flex"
              flexDirection="row"
              width="full"
              maxW={{ base: '100%', md: '31.4375rem' }}>
              <Image
                src="/img/lp/person1.png"
                alt="Card Image"
                objectFit={'contain'}
                maxW={{ base: '33.953vw', md: '10.8125rem' }}
                mr={6}
              />
              <Flex direction="column" justifyContent="center">
                <Text fontSize={{ md: 'md', base: '3.72vw' }} mb={{ md: 2, base: '1.8604vw' }} color={'#000000'}>
                  A ferramenta otimizou minha rotina. Corrijo redações com rapidez, critérios claros e os alunos têm feedback
                  ágil!
                </Text>
                <Text fontWeight="bold" fontSize={{ md: 'xs', base: '3.023vw' }} color={'#6B6B79'}>
                  Marcelo Oliveira <br />
                  professor de Língua Portuguesa
                </Text>
              </Flex>
            </Box>

            {/* Card 2 */}
            <Box
              bg="#F7F7F7"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              color="white"
              p={4}
              display="flex"
              flexDirection="row"
              width="full"
              maxW={{ base: '100%', md: '31.4375rem' }}>
              <Image
                src="/img/lp/person2.png"
                alt="Card Image"
                objectFit={'contain'}
                maxW={{ base: '33.953vw', md: '10.8125rem' }}
                mr={6}
              />
              <Flex direction="column" justifyContent="center">
                <Text fontSize={{ md: 'md', base: '3.72vw' }} mb={{ md: 2, base: '1.8604vw' }} color={'#000000'}>
                  Essa ferramenta otimizou o trabalho dos professores e melhorou o desempenho dos nossos alunos.
                </Text>
                <Text fontWeight="bold" fontSize={{ md: 'xs', base: '3.023vw' }} color={'#6B6B79'}>
                  Marcelo Oliveira <br />
                  professor de Língua Portuguesa
                </Text>
              </Flex>
            </Box>
          </Flex>

          {/* Seção de Tabs */}
          <VStack align="center" spacing={7} mt={{ md: 32, base: '19.0697vw' }} width="100%" display="none">
            <Heading
              as="h3"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              fontWeight={'bold'}
              fontSize={{ md: '2.92125rem', base: '10.697vw' }}
              color="brand.200">
              Como funciona?
            </Heading>

            <Tabs variant="unstyled" index={selectedTab} onChange={handleTabChange} width="100%" p={0}>
              <Box
                overflowX="auto"
                width="100%"
                p={0}
                css={{
                  '::-webkit-scrollbar': { display: 'none' },
                }}>
                <TabList
                  display="flex"
                  justifyContent={{ base: 'flex-start', md: 'center' }}
                  gap={3}
                  whiteSpace="nowrap"
                  paddingX={2}>
                  {['Professor', 'Professor Independente', 'Diretor/Gestor', 'Aluno'].map((role, index) => (
                    <Tab
                      key={index}
                      border="2px solid"
                      borderColor={index === selectedTab ? 'brand.200' : '#D7D7D7'}
                      backgroundColor={index === selectedTab ? 'brand.200' : 'white'}
                      color={index === selectedTab ? 'white' : 'brand.200'}
                      borderRadius={12}
                      fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                      _selected={{
                        backgroundColor: 'brand.200',
                        color: 'white',
                        borderColor: 'brand.200',
                      }}
                      p={2}
                      fontWeight="medium"
                      fontSize={'sm'}
                      minW="200px"
                      textAlign="center"
                      flexShrink={0}>
                      {role}
                    </Tab>
                  ))}
                </TabList>
              </Box>

              <TabPanels p={0} mt={0} display={'flex'} justifyContent={'center'}>
                {tabImages.map((imageSrc, index) => (
                  <TabPanel p={0} key={index} textAlign="center">
                    {index === selectedTab && (
                      <Image
                        src={imageSrc}
                        alt={`Tab ${index + 1} Image`}
                        maxW={{ md: '100%', base: '100vw' }}
                        width={{ base: '100vw', md: '58.375rem' }}
                        height={{ base: '51.3953vw', md: '30.0625rem' }}
                      />
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </VStack>
        </Container>
      </Box>

      <Flex
        direction="column"
        fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
        align="center"
        justify="center"
        bg="brand.200"
        w="100%"
        pb="4.5625rem"
        //temporario até incluir video//
        pt="4.5625rem"
        mt={{ md: '5.625rem', base: '10.93vw' }}
        //temporario até incluir video//
        // {/* Ocultado até chegar o video */}
        // mt={{ md: '-9rem', base: '-7rem' }}
        // pt="10rem"
      >
        {/* CTA */}
        <Box textAlign="center">
          <Text fontSize=".9375rem" fontWeight="bold" color="#FBFF00" mb={2}>
            Grátis e sem cartão de crédito
          </Text>
          <Button
            as="a"
            href="/cadastro"
            bg="#13FF69"
            color="#062989"
            border="2px solid #008CFF"
            px={8}
            py={8}
            fontSize="1.375rem"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            gap="1.875rem"
            lineHeight="unset"
            _hover={{ bg: '#0FCF5E' }}>
            Cadastre-se agora
            <Image src="/img/lp/arrow2.svg" alt="Seta" />
          </Button>
        </Box>
      </Flex>

      <Box as="section" bg="#F3F7FF" color="white">
        <Flex align="center" justify="center" direction={{ base: 'column', md: 'row' }}>
          <Box>
            <Image src="/img/lp/losango.svg" marginTop={'-2rem'} />
          </Box>
        </Flex>
        <Container maxW={{ md: '1024', base: '90%' }} px={0} pt={{ md: 16, base: '35.8139vw' }} className="container">
          <Flex
            align="flex-start"
            justify="center"
            gap={{ md: 24, base: '14.8837vw' }}
            direction={{ base: 'column-reverse', md: 'row' }}>
            {/* Imagem Central */}
            <Box display="flex" flexDirection="column" alignItems="flex-end" my={{ base: 8, md: 0 }}>
              <Image src="/img/lp/IA.svg" alt="Central Image" maxW="21.1875rem" objectFit="contain" />
              <Image
                src="/img/lp/seuAssistente.svg"
                alt="Seu assistente"
                width="11.4375rem"
                height="4rem"
                borderRadius="999px"
                backgroundColor="#062989"
                objectFit="cover"
                mt={1}
              />
            </Box>

            <Box>
              <Heading
                as="h2"
                fontSize="2rem"
                w={{ base: '100%', md: '18.75rem' }}
                color="brand.200"
                lineHeight={1}
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                mb="2.875rem">
                Agilize sua revisão com suporte de AI
              </Heading>
              <List
                spacing={4}
                styleType="none"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                fontSize="xl"
                color={'#000000'}>
                {[
                  'Gere revisões de texto customizáveis',
                  'Detecte cópias de outros textos',
                  'Gere títulos e descrições de temas',
                ].map((benefit, index) => (
                  <ListItem key={index} display="flex" alignItems="center">
                    <Image src="/img/lp/accept.svg" alt="Ícone de Aceite" mr={4} />
                    {benefit}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Flex>
        </Container>

        <Flex align="center" justify="center" direction={{ base: 'column', md: 'row' }} position="relative" bottom="-4rem">
          <Box>
            <Image src="/img/lp/hand-click.svg" />
          </Box>
        </Flex>
      </Box>

      <Box as="section" pt={44} pb={{ md: 52, base: '41.6279vw' }} position="relative">
        <Box position="absolute" top={{ md: '5rem', base: '-5vw' }} w={0} h={0} id="planos" visibility={'hidden'}>
          ref menu
        </Box>
        <Flex direction="column" align="center" maxW={{ md: '1024', base: '90%' }} mx={'auto'}>
          {/* Título */}
          <Heading
            as="h2"
            color="brand.200"
            textAlign="center"
            mb="8"
            fontSize={'2rem'}
            fontWeight={'extrabold'}
            w={{ base: '100%', md: '32.25rem' }}
            lineHeight={'120%'}
            fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
            letterSpacing={'0.08%'}>
            Encontre o plano que se encaixe com o seu perfil
          </Heading>

          {/* Parágrafo */}
          <Text
            color="#6B6B79"
            fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
            textAlign="center"
            mb="24"
            fontSize={'lg'}
            w={{ base: '100%', md: '37.5rem' }}>
            De escolas públicas, particulares ou professores independentes, nós pensamos em você.
          </Text>

          {/* Flex para os cards */}
          <Flex direction={{ base: 'column', md: 'row' }} justify="center">
            {/* Card 1 */}
            <Box
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              flex={1}
              bg="white"
              border="1px solid #D7D7D7"
              boxShadow="md"
              p="9">
              <Heading
                as="h3"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                fontSize="lg"
                fontWeight={'extrabold'}
                color="brand.200"
                mb="1.375rem">
                Gratuito
              </Heading>
              <Text color="#6B6B79" fontSize="lg" mb="10">
                Ideal para professores de cursinho e independentes.
              </Text>
              <UnorderedList spacing={2} mb="6" ml="0">
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Cadastro de monitores ilimitado
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  100 revisões gratuitas por mês
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Adicione mais revisões pagas se necessário
                </ListItem>
              </UnorderedList>
              <Button
                as="a"
                href="/cadastro"
                variant="outline"
                bg="#FFF"
                color="brand.200"
                border="3px solid"
                borderColor="brand.200"
                colorScheme="blue"
                display="flex"
                fontSize={'1.375rem'}
                justifyContent="space-between"
                alignItems="center"
                py={'1.0625rem'}
                pr={'1.4375rem'}
                pl={'1.875rem'}
                mt={'auto'}
                w={'100%'}
                height={'fit-content'}
                rightIcon={<Image src="/img/lp/arrow2.svg" alt="arrow" boxSize="16px" />}>
                Cadastre-se
              </Button>
            </Box>

            {/* Card 2 */}
            <Box
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              flex={1}
              bg="white"
              border="1px solid #D7D7D7"
              borderLeft={{ base: '1px solid #D7D7D7', md: '0' }}
              boxShadow="md"
              p="9">
              <Heading
                as="h3"
                fontSize="lg"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                fontWeight={'extrabold'}
                color="brand.200"
                mb="1.375rem">
                Comunitário
              </Heading>
              <Text color="#6B6B79" fontSize="lg" mb="10">
                Escolas públicas, ou professores de regiões carentes
              </Text>
              <UnorderedList spacing={2} mb="6" ml="0">
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Plano gratuito +
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  1000 revisões gratuitas por mês
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Adicione mais revisões pagas se necessário
                </ListItem>
              </UnorderedList>
              <Button
                as="a"
                href="https://wa.me/558191141119?text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20o%20correção%20online%20no%20plano%20comunitário."
                target="_blank"
                variant="outline"
                bg="#FFF"
                color="brand.200"
                border="3px solid"
                borderColor="brand.200"
                colorScheme="blue"
                display="flex"
                fontSize={'1.375rem'}
                justifyContent="space-between"
                alignItems="center"
                py={'1.0625rem'}
                pr={'1.4375rem'}
                pl={'1.875rem'}
                mt={'auto'}
                w={'100%'}
                height={'fit-content'}
                rightIcon={<Image src="/img/lp/arrow2.svg" alt="arrow" boxSize="16px" />}>
                Contato
              </Button>
            </Box>

            {/* Card 3 */}
            <Box
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              flex={1}
              bg="white"
              border="1px solid #D7D7D7"
              borderLeft={{ base: '1px solid #D7D7D7', md: '0' }}
              boxShadow="md"
              p="9">
              <Heading
                as="h3"
                fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                fontSize="lg"
                fontWeight={'extrabold'}
                color="brand.200"
                mb="1.375rem">
                Empresarial
              </Heading>
              <Text color="#6B6B79" fontSize="lg" mb="10">
                Escolas particulares com grandes turmas de alunos
              </Text>
              <UnorderedList spacing={2} mb="6" ml="0">
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Plano gratuito +
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Controle de professores e créditos
                </ListItem>
                <ListItem display="flex" alignItems="flex-start" color="#6B6B79" fontSize={'md'}>
                  <Image src="/img/lp/accept.svg" alt="accept icon" boxSize="20px" mr="2" />
                  Adicione mais revisões pagas se necessário
                </ListItem>
              </UnorderedList>
              <Button
                as="a"
                href="https://wa.me/558191141119?text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20o%20correção%20online%20no%20plano%20empresarial."
                target="_blank"
                variant="outline"
                bg="#FFF"
                color="brand.200"
                border="3px solid"
                borderColor="brand.200"
                colorScheme="blue"
                display="flex"
                fontSize={'1.375rem'}
                justifyContent="space-between"
                alignItems="center"
                py={'1.0625rem'}
                pr={'1.4375rem'}
                pl={'1.875rem'}
                mt={'auto'}
                w={'100%'}
                height={'fit-content'}
                rightIcon={<Image src="/img/lp/arrow2.svg" alt="arrow" boxSize="16px" />}>
                Contato
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box
        as="section"
        fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
        bg="#F3F7FF"
        pt={{ md: 28, base: '32.7906vw' }}
        pb={'5.4375rem'}
        position="relative">
        <Box position="absolute" top={{ md: '-6rem', base: '-5vw' }} visibility={'hidden'} w={0} h={0} id="faq">
          ref menu
        </Box>
        {/* Título da Seção */}
        <Box
          display={{ md: 'inline-block', base: 'none' }}
          bg="brand.200"
          position="absolute"
          top="-2.5rem"
          w={{ base: '80%', md: '50vw' }}
          left={{ base: '0', md: '0' }}
          px={6}
          py={4}>
          <Heading
            as="h2"
            fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
            fontSize="2.625rem"
            color="brand.200"
            fontWeight="extrabold"
            pl={{ base: '0', md: '6rem' }}>
            Perguntas e respostas
          </Heading>
        </Box>

        <Container maxW="1024" position="relative" display={'flex'} justifyContent={'center'}>
          <Box
            bg="brand.200"
            display="inline-block"
            position="absolute"
            top={{ md: '-9.5rem', base: '-49.5vw' }}
            w={{ base: '80%', md: 'fit-content' }}
            left={{ base: '0', md: '3rem' }}
            px={6}
            py={4}>
            <Heading
              as="h2"
              fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
              fontSize="2.625rem"
              color="#fff"
              fontWeight="extrabold"
              pl={{ base: '0', md: '0' }}>
              Perguntas e respostas
            </Heading>
          </Box>
          {/* Accordion de Perguntas e Respostas */}
          <Accordion
            allowToggle
            mt={6}
            defaultIndex={0}
            w={{ base: '100%', md: '52rem' }}
            bg={'#FFFFFF'}
            border={'1px solid #D7D7D7'}>
            {faqData.map((item, index) => (
              <AccordionItem key={index} borderBottom="1px solid #D7D7D7">
                <h2>
                  <AccordionButton py={'1.125rem'}>
                    <Box flex="1" textAlign="left" fontSize={'1.1875rem'} fontWeight="bold" color="#0A41D6">
                      {item.title}
                    </Box>
                    <AccordionIcon color="brand.200" />
                  </AccordionButton>
                </h2>
                <AccordionPanel p={0}>
                  <Text color="#6B6B79" fontSize={'1.0625rem'} pr={16} py={7} pl={5}>
                    {item.content}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Box>

      <Box as="footer" fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}>
        {/* Box 1 */}
        <Box id="contato" bg="brand.200" color="#FFF" py={'6.875rem'}>
          <Container maxW={{ base: '90%', md: '832' }} p={0}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify="flex-start"
              align={{ base: 'start', md: 'end' }}
              spacing={{ base: '82px', md: '168px' }}>
              {/* Dúvidas */}
              <VStack align="start" spacing={4} w={{ base: '100%', md: '22.8125rem' }}>
                <Heading
                  as="h2"
                  fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily}
                  color="#FFFF"
                  fontSize="2.625rem"
                  fontWeight={'extrabold'}>
                  Dúvidas?
                </Heading>
                <Text fontSize={'1.0625rem'} lineHeight={'170%'} fontWeight={'normal'}>
                  Entre em contato e tire suas dúvidas!
                </Text>
                <Flex
                  as="a"
                  href="mailto:suporte@correcao-online.com"
                  alignItems="center"
                  gap={3}
                  color="#FFF"
                  fontWeight="bold"
                  target="_blank"
                  fontSize={'1.1875rem'}
                  lineHeight={'170%'}>
                  <Image src="/img/lp/ic-email.svg" alt="Email Icon" boxSize={6} />
                  suporte@correcao-online.com
                </Flex>
                <Flex
                  as="a"
                  href="https://wa.me/558191141119?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20corre%C3%A7%C3%A3o%20online."
                  alignItems="center"
                  gap={3}
                  color="#FFF"
                  fontWeight="bold"
                  target="_blank"
                  fontSize={'1.1875rem'}
                  lineHeight={'170%'}>
                  <Icon as={FaWhatsapp} />
                  ou via WhatsApp
                </Flex>
              </VStack>

              {/* Redes Sociais */}
              <VStack align="start" spacing={4}>
                <Text fontSize={'1.0625rem'} lineHeight={'170%'} fontWeight={'normal'}>
                  Siga nas redes sociais
                </Text>
                <HStack spacing={6}>
                  <Flex as="a" href="https://www.linkedin.com/company/corre%C3%A7%C3%A3o-online/" target="_blank">
                    <Image
                      src="/img/lp/linkedin-icon.svg"
                      alt="LinkedIn"
                      boxSize={12}
                      // w={'3.1875rem'}
                    />
                  </Flex>
                  <Flex as="a" href="https://www.instagram.com/correcao_online/" target="_blank">
                    <Image
                      src="/img/lp/instagram-icon.svg"
                      alt="Instagram"
                      boxSize={12}
                      // w={'3.1875rem'}
                    />
                  </Flex>
                </HStack>
              </VStack>
            </Stack>
          </Container>
        </Box>

        {/* Box 2 */}
        <Box bg="#FFF" fontFamily={fontsUtils.publicSansBoldAbsoluteZero.fontFamily} py={4} textAlign="center">
          <Container maxW={{ base: '90%', md: '832' }} p={0}>
            <HStack justify="space-between" spacing={4}>
              <Text fontSize="sm" color="#6B6B79">
                Todos os direitos reservados
              </Text>
              <Image
                src="/img/lp/logo.svg"
                alt="Logo Correção Online"
                // boxSize={20}
                w={'6.4375rem'}
              />
            </HStack>
          </Container>
        </Box>
      </Box>
    </>
  )
}
