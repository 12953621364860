/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */

export type UserRole = typeof UserRole[keyof typeof UserRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  NUMBER_0: 0,
  NUMBER_10: 10,
  NUMBER_15: 15,
  NUMBER_17: 17,
  NUMBER_20: 20,
  NUMBER_30: 30,
} as const;
