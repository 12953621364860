/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BasicGroupViewModelListResultViewModel,
  CompetencyViewModelResultViewModel,
  GetApiV1SchoolSchoolAdmParams,
  GroupViewModelResultViewModel,
  IdListViewModel,
  InstitutionViewModelListResultViewModel,
  InstitutionViewModelResultViewModel,
  NewAdmAnonymousViewModel,
  NewBatchStudentsViewModel,
  NewCompetencyViewModel,
  NewInstitutionViewModel,
  NewSchoolGroupViewModel,
  NewSchoolViewModel,
  ProblemDetails,
  SchoolAnalyticsVieWModelListResultViewModel,
  SchoolEssayAdmViewModelListResultViewModel,
  SchoolViewModelListResultViewModel,
  SchoolViewModelResultViewModel,
  StringResultViewModel,
  UpdateCompetencyViewModel,
  UpdateInstitutionViewModel,
  UpdateSchoolGroupViewModel,
  UpdateSchoolThumbnailViewModel,
  UpdateSchoolViewModel
} from './types'




/**
 * @summary Adds a new school
 */
export const postApiV1School = (
    newSchoolViewModel: NewSchoolViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StringResultViewModel>> => {
    
    
    return axios.post(
      `/api/v1/school`,
      newSchoolViewModel,options
    );
  }



export const getPostApiV1SchoolMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1School>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewSchoolViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1School'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1School>>, {data: NewSchoolViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1School(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NewSchoolViewModel}, TContext>}

    export type PostApiV1SchoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1School>>>
    export type PostApiV1SchoolMutationBody = NewSchoolViewModel
    export type PostApiV1SchoolMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new school
 */
export const usePostApiV1School = <TData = Awaited<ReturnType<typeof postApiV1School>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewSchoolViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: NewSchoolViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all schools that belongs to logged user
 */
export const getApiV1School = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolViewModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school`,options
    );
  }


export const getGetApiV1SchoolQueryKey = () => {
    return [`/api/v1/school`] as const;
    }

    
export const getGetApiV1SchoolQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1School>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1School>>> = ({ signal }) => getApiV1School({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1School>>>
export type GetApiV1SchoolQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1School<TData = Awaited<ReturnType<typeof getApiV1School>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1School>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1School<TData = Awaited<ReturnType<typeof getApiV1School>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1School>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1School<TData = Awaited<ReturnType<typeof getApiV1School>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Lists all schools that belongs to logged user
 */

export function useGetApiV1School<TData = Awaited<ReturnType<typeof getApiV1School>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1School>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates basic information of school
 */
export const putApiV1School = (
    updateSchoolViewModel: UpdateSchoolViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/school`,
      updateSchoolViewModel,options
    );
  }



export const getPutApiV1SchoolMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1School>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSchoolViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1School'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1School>>, {data: UpdateSchoolViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1School(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateSchoolViewModel}, TContext>}

    export type PutApiV1SchoolMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1School>>>
    export type PutApiV1SchoolMutationBody = UpdateSchoolViewModel
    export type PutApiV1SchoolMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic information of school
 */
export const usePutApiV1School = <TData = Awaited<ReturnType<typeof putApiV1School>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSchoolViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateSchoolViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SchoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Retrieves the info regarding essay status per school
 */
export const getApiV1SchoolSchoolAdm = (
    params?: GetApiV1SchoolSchoolAdmParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolEssayAdmViewModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/school-adm`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1SchoolSchoolAdmQueryKey = (params?: GetApiV1SchoolSchoolAdmParams,) => {
    return [`/api/v1/school/school-adm`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1SchoolSchoolAdmQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1SchoolSchoolAdmParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolAdmQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>> = ({ signal }) => getApiV1SchoolSchoolAdm(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolAdmQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>>
export type GetApiV1SchoolSchoolAdmQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolAdm<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1SchoolSchoolAdmParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolAdm<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1SchoolSchoolAdmParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolAdm<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1SchoolSchoolAdmParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Retrieves the info regarding essay status per school
 */

export function useGetApiV1SchoolSchoolAdm<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1SchoolSchoolAdmParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolAdmQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Lists all schools that belongs to a given user
 */
export const getApiV1SchoolUserUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolViewModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/user/${userId}`,options
    );
  }


export const getGetApiV1SchoolUserUserIdQueryKey = (userId: string,) => {
    return [`/api/v1/school/user/${userId}`] as const;
    }

    
export const getGetApiV1SchoolUserUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError = AxiosError<ProblemDetails | void>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolUserUserIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>> = ({ signal }) => getApiV1SchoolUserUserId(userId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolUserUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>>
export type GetApiV1SchoolUserUserIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolUserUserId<TData = Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolUserUserId<TData = Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolUserUserId<TData = Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Lists all schools that belongs to a given user
 */

export function useGetApiV1SchoolUserUserId<TData = Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolUserUserIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets a school
 */
export const getApiV1SchoolId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolViewModelResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${id}`,options
    );
  }


export const getGetApiV1SchoolIdQueryKey = (id: string,) => {
    return [`/api/v1/school/${id}`] as const;
    }

    
export const getGetApiV1SchoolIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolId>>> = ({ signal }) => getApiV1SchoolId(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolId>>>
export type GetApiV1SchoolIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolId<TData = Awaited<ReturnType<typeof getApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolId<TData = Awaited<ReturnType<typeof getApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolId<TData = Awaited<ReturnType<typeof getApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Gets a school
 */

export function useGetApiV1SchoolId<TData = Awaited<ReturnType<typeof getApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a school
 */
export const deleteApiV1SchoolId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.delete(
      `/api/v1/school/${id}`,options
    );
  }



export const getDeleteApiV1SchoolIdMutationOptions = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteApiV1SchoolId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1SchoolId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV1SchoolId(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type DeleteApiV1SchoolIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1SchoolId>>>
    
    export type DeleteApiV1SchoolIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Deletes a school
 */
export const useDeleteApiV1SchoolId = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1SchoolIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates school thumbnail
 */
export const putApiV1SchoolUpdateThumbnail = (
    updateSchoolThumbnailViewModel: UpdateSchoolThumbnailViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/school/update-thumbnail`,
      updateSchoolThumbnailViewModel,options
    );
  }



export const getPutApiV1SchoolUpdateThumbnailMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1SchoolUpdateThumbnail>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSchoolThumbnailViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1SchoolUpdateThumbnail'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1SchoolUpdateThumbnail>>, {data: UpdateSchoolThumbnailViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1SchoolUpdateThumbnail(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateSchoolThumbnailViewModel}, TContext>}

    export type PutApiV1SchoolUpdateThumbnailMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1SchoolUpdateThumbnail>>>
    export type PutApiV1SchoolUpdateThumbnailMutationBody = UpdateSchoolThumbnailViewModel
    export type PutApiV1SchoolUpdateThumbnailMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates school thumbnail
 */
export const usePutApiV1SchoolUpdateThumbnail = <TData = Awaited<ReturnType<typeof putApiV1SchoolUpdateThumbnail>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSchoolThumbnailViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateSchoolThumbnailViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SchoolUpdateThumbnailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all groups of a school
 */
export const getApiV1SchoolSchoolIdGroup = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BasicGroupViewModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${schoolId}/group`,options
    );
  }


export const getGetApiV1SchoolSchoolIdGroupQueryKey = (schoolId: string,) => {
    return [`/api/v1/school/${schoolId}/group`] as const;
    }

    
export const getGetApiV1SchoolSchoolIdGroupQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolIdGroupQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>> = ({ signal }) => getApiV1SchoolSchoolIdGroup(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolIdGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>>
export type GetApiV1SchoolSchoolIdGroupQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolIdGroup<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdGroup<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdGroup<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Lists all groups of a school
 */

export function useGetApiV1SchoolSchoolIdGroup<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroup>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolIdGroupQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Adds a new group to an existing school
 */
export const postApiV1SchoolSchoolIdGroup = (
    schoolId: string,
    newSchoolGroupViewModel: NewSchoolGroupViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/${schoolId}/group`,
      newSchoolGroupViewModel,options
    );
  }



export const getPostApiV1SchoolSchoolIdGroupMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: NewSchoolGroupViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolSchoolIdGroup'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroup>>, {schoolId: string;data: NewSchoolGroupViewModel}> = (props) => {
          const {schoolId,data} = props ?? {};

          return  postApiV1SchoolSchoolIdGroup(schoolId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;data: NewSchoolGroupViewModel}, TContext>}

    export type PostApiV1SchoolSchoolIdGroupMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroup>>>
    export type PostApiV1SchoolSchoolIdGroupMutationBody = NewSchoolGroupViewModel
    export type PostApiV1SchoolSchoolIdGroupMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new group to an existing school
 */
export const usePostApiV1SchoolSchoolIdGroup = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: NewSchoolGroupViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;data: NewSchoolGroupViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolSchoolIdGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic information about a given group
 */
export const putApiV1SchoolSchoolIdGroup = (
    schoolId: string,
    updateSchoolGroupViewModel: UpdateSchoolGroupViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/school/${schoolId}/group`,
      updateSchoolGroupViewModel,options
    );
  }



export const getPutApiV1SchoolSchoolIdGroupMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: UpdateSchoolGroupViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1SchoolSchoolIdGroup'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdGroup>>, {schoolId: string;data: UpdateSchoolGroupViewModel}> = (props) => {
          const {schoolId,data} = props ?? {};

          return  putApiV1SchoolSchoolIdGroup(schoolId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;data: UpdateSchoolGroupViewModel}, TContext>}

    export type PutApiV1SchoolSchoolIdGroupMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdGroup>>>
    export type PutApiV1SchoolSchoolIdGroupMutationBody = UpdateSchoolGroupViewModel
    export type PutApiV1SchoolSchoolIdGroupMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic information about a given group
 */
export const usePutApiV1SchoolSchoolIdGroup = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdGroup>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: UpdateSchoolGroupViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;data: UpdateSchoolGroupViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SchoolSchoolIdGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets all details of a specific group
 */
export const getApiV1SchoolSchoolIdGroupGroupId = (
    schoolId: string,
    groupId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GroupViewModelResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${schoolId}/group/${groupId}`,options
    );
  }


export const getGetApiV1SchoolSchoolIdGroupGroupIdQueryKey = (schoolId: string,
    groupId: string,) => {
    return [`/api/v1/school/${schoolId}/group/${groupId}`] as const;
    }

    
export const getGetApiV1SchoolSchoolIdGroupGroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolIdGroupGroupIdQueryKey(schoolId,groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>> = ({ signal }) => getApiV1SchoolSchoolIdGroupGroupId(schoolId,groupId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId && groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolIdGroupGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>>
export type GetApiV1SchoolSchoolIdGroupGroupIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Gets all details of a specific group
 */

export function useGetApiV1SchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolIdGroupGroupIdQueryOptions(schoolId,groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a batch of students for given school
 */
export const postApiV1SchoolStudents = (
    newBatchStudentsViewModel: NewBatchStudentsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/students`,
      newBatchStudentsViewModel,options
    );
  }



export const getPostApiV1SchoolStudentsMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolStudents>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewBatchStudentsViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolStudents'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolStudents>>, {data: NewBatchStudentsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1SchoolStudents(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NewBatchStudentsViewModel}, TContext>}

    export type PostApiV1SchoolStudentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolStudents>>>
    export type PostApiV1SchoolStudentsMutationBody = NewBatchStudentsViewModel
    export type PostApiV1SchoolStudentsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a batch of students for given school
 */
export const usePostApiV1SchoolStudents = <TData = Awaited<ReturnType<typeof postApiV1SchoolStudents>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewBatchStudentsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: NewBatchStudentsViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolStudentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes the group of the given school
 */
export const deleteApiV1SchoolSchoolIdGroupId = (
    schoolId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.delete(
      `/api/v1/school/${schoolId}/group/${id}`,options
    );
  }



export const getDeleteApiV1SchoolSchoolIdGroupIdMutationOptions = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteApiV1SchoolSchoolIdGroupId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdGroupId>>, {schoolId: string;id: string}> = (props) => {
          const {schoolId,id} = props ?? {};

          return  deleteApiV1SchoolSchoolIdGroupId(schoolId,id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;id: string}, TContext>}

    export type DeleteApiV1SchoolSchoolIdGroupIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdGroupId>>>
    
    export type DeleteApiV1SchoolSchoolIdGroupIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes the group of the given school
 */
export const useDeleteApiV1SchoolSchoolIdGroupId = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1SchoolSchoolIdGroupIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Attaches one or more student to a group
 */
export const postApiV1SchoolSchoolIdGroupGroupIdAddStudents = (
    schoolId: string,
    groupId: string,
    idListViewModel: IdListViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/${schoolId}/group/${groupId}/add-students`,
      idListViewModel,options
    );
  }



export const getPostApiV1SchoolSchoolIdGroupGroupIdAddStudentsMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddStudents>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolSchoolIdGroupGroupIdAddStudents'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddStudents>>, {schoolId: string;groupId: string;data: IdListViewModel}> = (props) => {
          const {schoolId,groupId,data} = props ?? {};

          return  postApiV1SchoolSchoolIdGroupGroupIdAddStudents(schoolId,groupId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>}

    export type PostApiV1SchoolSchoolIdGroupGroupIdAddStudentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddStudents>>>
    export type PostApiV1SchoolSchoolIdGroupGroupIdAddStudentsMutationBody = IdListViewModel
    export type PostApiV1SchoolSchoolIdGroupGroupIdAddStudentsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Attaches one or more student to a group
 */
export const usePostApiV1SchoolSchoolIdGroupGroupIdAddStudents = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddStudents>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;groupId: string;data: IdListViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolSchoolIdGroupGroupIdAddStudentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Attaches one or more teacher to a group
 */
export const postApiV1SchoolSchoolIdGroupGroupIdAddTeachers = (
    schoolId: string,
    groupId: string,
    idListViewModel: IdListViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/${schoolId}/group/${groupId}/add-teachers`,
      idListViewModel,options
    );
  }



export const getPostApiV1SchoolSchoolIdGroupGroupIdAddTeachersMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddTeachers>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolSchoolIdGroupGroupIdAddTeachers'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddTeachers>>, {schoolId: string;groupId: string;data: IdListViewModel}> = (props) => {
          const {schoolId,groupId,data} = props ?? {};

          return  postApiV1SchoolSchoolIdGroupGroupIdAddTeachers(schoolId,groupId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>}

    export type PostApiV1SchoolSchoolIdGroupGroupIdAddTeachersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddTeachers>>>
    export type PostApiV1SchoolSchoolIdGroupGroupIdAddTeachersMutationBody = IdListViewModel
    export type PostApiV1SchoolSchoolIdGroupGroupIdAddTeachersMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Attaches one or more teacher to a group
 */
export const usePostApiV1SchoolSchoolIdGroupGroupIdAddTeachers = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdGroupGroupIdAddTeachers>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;groupId: string;data: IdListViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;groupId: string;data: IdListViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolSchoolIdGroupGroupIdAddTeachersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Adds a new competency for an institution
 */
export const postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency = (
    schoolId: string,
    institutionId: string,
    newCompetencyViewModel: NewCompetencyViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/${schoolId}/institution/${institutionId}/competency`,
      newCompetencyViewModel,options
    );
  }



export const getPostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: NewCompetencyViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, {schoolId: string;institutionId: string;data: NewCompetencyViewModel}> = (props) => {
          const {schoolId,institutionId,data} = props ?? {};

          return  postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency(schoolId,institutionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: NewCompetencyViewModel}, TContext>}

    export type PostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>>
    export type PostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationBody = NewCompetencyViewModel
    export type PostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new competency for an institution
 */
export const usePostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: NewCompetencyViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;institutionId: string;data: NewCompetencyViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates institution's competency data
 */
export const putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency = (
    schoolId: string,
    institutionId: string,
    updateCompetencyViewModel: UpdateCompetencyViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/school/${schoolId}/institution/${institutionId}/competency`,
      updateCompetencyViewModel,options
    );
  }



export const getPutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: UpdateCompetencyViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, {schoolId: string;institutionId: string;data: UpdateCompetencyViewModel}> = (props) => {
          const {schoolId,institutionId,data} = props ?? {};

          return  putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency(schoolId,institutionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: UpdateCompetencyViewModel}, TContext>}

    export type PutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>>
    export type PutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationBody = UpdateCompetencyViewModel
    export type PutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates institution's competency data
 */
export const usePutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;data: UpdateCompetencyViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;institutionId: string;data: UpdateCompetencyViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a competency from an institution
 */
export const deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId = (
    schoolId: string,
    institutionId: string,
    competencyId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.delete(
      `/api/v1/school/${schoolId}/institution/${institutionId}/competency/${competencyId}`,options
    );
  }



export const getDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdMutationOptions = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;competencyId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, {schoolId: string;institutionId: string;competencyId: string}> = (props) => {
          const {schoolId,institutionId,competencyId} = props ?? {};

          return  deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId(schoolId,institutionId,competencyId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;competencyId: string}, TContext>}

    export type DeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>>
    
    export type DeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a competency from an institution
 */
export const useDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string;competencyId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;institutionId: string;competencyId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets a specific competency
 */
export const getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId = (
    schoolId: string,
    institutionId: string,
    competencyId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CompetencyViewModelResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${schoolId}/institution/${institutionId}/competency/${competencyId}`,options
    );
  }


export const getGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryKey = (schoolId: string,
    institutionId: string,
    competencyId: string,) => {
    return [`/api/v1/school/${schoolId}/institution/${institutionId}/competency/${competencyId}`] as const;
    }

    
export const getGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string,
    institutionId: string,
    competencyId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryKey(schoolId,institutionId,competencyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>> = ({ signal }) => getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId(schoolId,institutionId,competencyId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId && institutionId && competencyId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>>
export type GetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string,
    competencyId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string,
    competencyId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string,
    competencyId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Gets a specific competency
 */

export function useGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string,
    competencyId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyIdQueryOptions(schoolId,institutionId,competencyId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Adds a new institution to a given school
 */
export const postApiV1SchoolSchoolIdInstitution = (
    schoolId: string,
    newInstitutionViewModel: NewInstitutionViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/${schoolId}/institution`,
      newInstitutionViewModel,options
    );
  }



export const getPostApiV1SchoolSchoolIdInstitutionMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: NewInstitutionViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolSchoolIdInstitution'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitution>>, {schoolId: string;data: NewInstitutionViewModel}> = (props) => {
          const {schoolId,data} = props ?? {};

          return  postApiV1SchoolSchoolIdInstitution(schoolId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;data: NewInstitutionViewModel}, TContext>}

    export type PostApiV1SchoolSchoolIdInstitutionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitution>>>
    export type PostApiV1SchoolSchoolIdInstitutionMutationBody = NewInstitutionViewModel
    export type PostApiV1SchoolSchoolIdInstitutionMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new institution to a given school
 */
export const usePostApiV1SchoolSchoolIdInstitution = <TData = Awaited<ReturnType<typeof postApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: NewInstitutionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;data: NewInstitutionViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolSchoolIdInstitutionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates institution data
 */
export const putApiV1SchoolSchoolIdInstitution = (
    schoolId: string,
    updateInstitutionViewModel: UpdateInstitutionViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.put(
      `/api/v1/school/${schoolId}/institution`,
      updateInstitutionViewModel,options
    );
  }



export const getPutApiV1SchoolSchoolIdInstitutionMutationOptions = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: UpdateInstitutionViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['putApiV1SchoolSchoolIdInstitution'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitution>>, {schoolId: string;data: UpdateInstitutionViewModel}> = (props) => {
          const {schoolId,data} = props ?? {};

          return  putApiV1SchoolSchoolIdInstitution(schoolId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;data: UpdateInstitutionViewModel}, TContext>}

    export type PutApiV1SchoolSchoolIdInstitutionMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitution>>>
    export type PutApiV1SchoolSchoolIdInstitutionMutationBody = UpdateInstitutionViewModel
    export type PutApiV1SchoolSchoolIdInstitutionMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates institution data
 */
export const usePutApiV1SchoolSchoolIdInstitution = <TData = Awaited<ReturnType<typeof putApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;data: UpdateInstitutionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;data: UpdateInstitutionViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1SchoolSchoolIdInstitutionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all institutions of a given school together with their competencies
 */
export const getApiV1SchoolSchoolIdInstitution = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InstitutionViewModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${schoolId}/institution`,options
    );
  }


export const getGetApiV1SchoolSchoolIdInstitutionQueryKey = (schoolId: string,) => {
    return [`/api/v1/school/${schoolId}/institution`] as const;
    }

    
export const getGetApiV1SchoolSchoolIdInstitutionQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolIdInstitutionQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>> = ({ signal }) => getApiV1SchoolSchoolIdInstitution(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolIdInstitutionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>>
export type GetApiV1SchoolSchoolIdInstitutionQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolIdInstitution<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitution<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitution<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Lists all institutions of a given school together with their competencies
 */

export function useGetApiV1SchoolSchoolIdInstitution<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitution>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolIdInstitutionQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Removes an institution from a given school
 */
export const deleteApiV1SchoolSchoolIdInstitutionInstitutionId = (
    schoolId: string,
    institutionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.delete(
      `/api/v1/school/${schoolId}/institution/${institutionId}`,options
    );
  }



export const getDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdMutationOptions = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteApiV1SchoolSchoolIdInstitutionInstitutionId'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionId>>, {schoolId: string;institutionId: string}> = (props) => {
          const {schoolId,institutionId} = props ?? {};

          return  deleteApiV1SchoolSchoolIdInstitutionInstitutionId(schoolId,institutionId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{schoolId: string;institutionId: string}, TContext>}

    export type DeleteApiV1SchoolSchoolIdInstitutionInstitutionIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionId>>>
    
    export type DeleteApiV1SchoolSchoolIdInstitutionInstitutionIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes an institution from a given school
 */
export const useDeleteApiV1SchoolSchoolIdInstitutionInstitutionId = <TData = Awaited<ReturnType<typeof deleteApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{schoolId: string;institutionId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {schoolId: string;institutionId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets a specific institution
 */
export const getApiV1SchoolSchoolIdInstitutionInstitutionId = (
    schoolId: string,
    institutionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InstitutionViewModelResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/${schoolId}/institution/${institutionId}`,options
    );
  }


export const getGetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryKey = (schoolId: string,
    institutionId: string,) => {
    return [`/api/v1/school/${schoolId}/institution/${institutionId}`] as const;
    }

    
export const getGetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string,
    institutionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryKey(schoolId,institutionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>> = ({ signal }) => getApiV1SchoolSchoolIdInstitutionInstitutionId(schoolId,institutionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId && institutionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>>
export type GetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolSchoolIdInstitutionInstitutionId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitutionInstitutionId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolSchoolIdInstitutionInstitutionId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Gets a specific institution
 */

export function useGetApiV1SchoolSchoolIdInstitutionInstitutionId<TData = Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    institutionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolSchoolIdInstitutionInstitutionId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolSchoolIdInstitutionInstitutionIdQueryOptions(schoolId,institutionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the analysis of each institution per school of the logged adm user (ADM or Independent Teacher)
 */
export const getApiV1SchoolInstitutionPerSchool = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolAnalyticsVieWModelListResultViewModel>> => {
    
    
    return axios.get(
      `/api/v1/school/institution-per-school`,options
    );
  }


export const getGetApiV1SchoolInstitutionPerSchoolQueryKey = () => {
    return [`/api/v1/school/institution-per-school`] as const;
    }

    
export const getGetApiV1SchoolInstitutionPerSchoolQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1SchoolInstitutionPerSchoolQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>> = ({ signal }) => getApiV1SchoolInstitutionPerSchool({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiV1SchoolInstitutionPerSchoolQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>>
export type GetApiV1SchoolInstitutionPerSchoolQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1SchoolInstitutionPerSchool<TData = Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolInstitutionPerSchool<TData = Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiV1SchoolInstitutionPerSchool<TData = Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Gets the analysis of each institution per school of the logged adm user (ADM or Independent Teacher)
 */

export function useGetApiV1SchoolInstitutionPerSchool<TData = Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1SchoolInstitutionPerSchool>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiV1SchoolInstitutionPerSchoolQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new ADM user with school and institution (optional)
 */
export const postApiV1SchoolNewAdm = (
    newAdmAnonymousViewModel: NewAdmAnonymousViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    
    return axios.post(
      `/api/v1/school/new-adm`,
      newAdmAnonymousViewModel,options
    );
  }



export const getPostApiV1SchoolNewAdmMutationOptions = <TData = Awaited<ReturnType<typeof postApiV1SchoolNewAdm>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewAdmAnonymousViewModel}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['postApiV1SchoolNewAdm'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SchoolNewAdm>>, {data: NewAdmAnonymousViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1SchoolNewAdm(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NewAdmAnonymousViewModel}, TContext>}

    export type PostApiV1SchoolNewAdmMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SchoolNewAdm>>>
    export type PostApiV1SchoolNewAdmMutationBody = NewAdmAnonymousViewModel
    export type PostApiV1SchoolNewAdmMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new ADM user with school and institution (optional)
 */
export const usePostApiV1SchoolNewAdm = <TData = Awaited<ReturnType<typeof postApiV1SchoolNewAdm>>, TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NewAdmAnonymousViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: NewAdmAnonymousViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1SchoolNewAdmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    