import type { EssayCompetencyViewModel } from '@/services/types'
import { Button, Tooltip, VStack } from '@chakra-ui/react'
import { HStack, Heading, Input, Stack } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'

type GradeProps = {
  competencies: EssayCompetencyViewModel[]
  isAbleToReview?: boolean
  setNewGrade?: (e: ChangeEvent<HTMLInputElement>) => void
  submitGrades?: () => void
  isGradesInvalid?: boolean
  hasGradesChanged?: boolean
  isSavingGrades?: boolean
  totalGrade: number
}

import { useEffect, useState } from 'react'

export const Grades = (props: GradeProps) => {
  const { competencies, isAbleToReview, setNewGrade, submitGrades, isGradesInvalid, hasGradesChanged, isSavingGrades } = props

  const [totalGrade, setTotalGrade] = useState(0)

  useEffect(() => {
    const grades = competencies.map((competency) => competency.grade)
    const validGrades = grades.filter((grade): grade is number => grade != null && grade > 0)

    if (validGrades.length > 0) {
      const average = validGrades.length > 0 ? validGrades.reduce((acc, grade) => acc + grade, 0) / validGrades.length : 0
      setTotalGrade(average)
    } else {
      setTotalGrade(0)
    }
  }, [competencies])

  return (
    <>
      <Heading fontSize="sm">Avaliação das competências</Heading>
      <Stack borderRadius="sm" border="1px solid #F9FAFF" bgColor="gray.50" shadow="lg">
        <Stack py={2} px={6}>
          {competencies.map((competency) => (
            <HStack key={competency.id} justifyContent="space-between">
              <Heading cursor="default" fontSize="sm" color="gray.500">
                <Tooltip placement="end" label={competency.description}>
                  <span>{competency.name}</span>
                </Tooltip>
              </Heading>
              <Input
                id={competency.id}
                name={competency.name || ''}
                borderWidth={isAbleToReview ? 1 : 0}
                borderColor="gray.200"
                bgColor={isAbleToReview ? 'white' : 'none'}
                color={isAbleToReview ? 'black' : 'gray.500'}
                width="100px"
                textAlign="right"
                readOnly={!isAbleToReview}
                pointerEvents={isAbleToReview ? 'auto' : 'none'}
                value={competency.grade}
                onChange={setNewGrade}
                onFocus={(e) => e.target.select()}
              />
            </HStack>
          ))}
        </Stack>
        <VStack p={0} bgColor="gray.100">
          <HStack py={2} px={6} width="100%" justifyContent="space-between">
            <Heading fontSize="xs">Nota final</Heading>
            <Input
              borderColor="gray.200"
              width="100px"
              bgColor={isAbleToReview ? 'white' : 'none'}
              borderWidth={isAbleToReview ? 1 : 0}
              color={isAbleToReview ? 'black' : 'gray.500'}
              readOnly
              pointerEvents="none"
              textAlign="right"
              value={Number.parseFloat(totalGrade.toFixed(1))}
            />
          </HStack>
          <HStack width="100%" hidden={!isAbleToReview} p={3} pt={0} onClick={submitGrades}>
            <Button width="100%" variant="primary" isDisabled={isGradesInvalid || !hasGradesChanged} isLoading={isSavingGrades}>
              Salvar notas
            </Button>
          </HStack>
        </VStack>
      </Stack>
    </>
  )
}
