import { EssayReview } from '@/components/EssayArea/EssayReview'
import PageSpinner from '@/components/PageSpinner'
import Page from '@/pages/Page'
import { useGetApiV1EssayEssayId } from '@/services/essay'
import { useGetApiV1StudentActivityActivityId } from '@/services/student'
import useNavigationStore from '@/store/useNavigationStore'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, HStack, IconButton } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EssayFloatingButtons } from './EssayFloatingButtons'
import { EssaySidebar } from './EssaySidebar'
import { EssayStudentCreate } from './EssayStudentCreate'

type Params = {
  essayID: string
}

const EssayStudent = () => {
  const params = useParams() as Params

  const getApiV1Essay = useGetApiV1EssayEssayId(params.essayID)
  const essay = getApiV1Essay.data?.data.message
  const isEssayImage = !!essay?.attachment
  const isEssayInProgress = essay?.status === 10

  const getApiV1StudentActivityId = useGetApiV1StudentActivityActivityId(essay?.activity?.id || '', {
    query: { enabled: false },
  })
  const activity = getApiV1StudentActivityId.data?.data.message

  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()
  const handleBackNavigation = () => {
    setReturnTo('/area-de-atividades')
    navigate(`/detalhamento-atividade/${activity?.id}`)
  }

  useEffect(() => {
    if (essay?.activity?.id) getApiV1StudentActivityId.refetch()
  }, [essay?.activity?.id])

  if (getApiV1StudentActivityId.isPending || getApiV1Essay.isLoading || !activity || !essay) return <PageSpinner />

  return (
    <Page authPage={true} allowedUserType={'student'}>
      <HStack width="100%" height="100%" spacing={0}>
        <IconButton
          position="absolute"
          left={2}
          top={2}
          zIndex={2}
          onClick={handleBackNavigation}
          aria-label="Voltar"
          isRound
          icon={<ArrowBackIcon />}
          color="brand.200"
        />
        <Flex position="relative" flex={1} height="100%">
          <EssayFloatingButtons essay={essay} isEssayInProgress={isEssayInProgress} />
          {isEssayInProgress && <EssayStudentCreate essay={essay} activity={activity} />}
          {!isEssayInProgress && (
            <EssayReview
              essay={essay}
              isAbleToReview={false}
              isEssayImage={isEssayImage}
              competencies={essay.competencies || []}
            />
          )}
        </Flex>
        <EssaySidebar activity={activity} essay={essay} isEssayInProgress={isEssayInProgress} />
      </HStack>
    </Page>
  )
}

export default EssayStudent
