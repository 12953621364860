/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */

export type AssistantPriceType = typeof AssistantPriceType[keyof typeof AssistantPriceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssistantPriceType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
