/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */

export interface SchoolAddressViewModel {
  /** @nullable */
  street?: string | null;
  /** @nullable */
  number?: string | null;
  /** @nullable */
  zipCode?: string | null;
  /** @nullable */
  city?: string | null;
  /** @nullable */
  state?: string | null;
  /** @nullable */
  country?: string | null;
  /** @nullable */
  complement?: string | null;
}
