/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface ActivityWithTotalsViewModel {
  id?: string;
  /** @nullable */
  subject?: string | null;
  /** @nullable */
  description?: string | null;
  institution?: KeyValueViewModel;
  school?: KeyValueViewModel;
  deadline?: string;
  createdAt?: string;
  validFrom?: string;
  theme?: KeyValueViewModel;
  teacher?: KeyValueViewModel;
  amountEssaysDraft?: number;
  amountEssaysSubmitted?: number;
  amountEssaysReviewed?: number;
  amountEssaysClosed?: number;
}
