/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface GroupViewModel {
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  description?: string | null;
  shift?: number;
  /** @nullable */
  teachers?: KeyValueViewModel[] | null;
  /** @nullable */
  students?: KeyValueViewModel[] | null;
}
