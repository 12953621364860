import { usePostApiV1Teacher } from '@/services/teacher'
import type { NewTeacherViewModel } from '@/services/types'
import useLoggedUserStore from '@/store/useLoggedUserStore'
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { MultiSelect } from 'chakra-multiselect'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { inputPhoneMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  name: yup.string().min(5).max(60).required(),
  email: yup.string().email().required(),
  phoneNumber: yup.string().phone().nullable(),
  groups: yup.array().min(1).required(),
})

type FormValues = yup.InferType<typeof validationSchema>

interface Group {
  id: string
  name: string
  label: string
}

interface Option {
  schoolId: string
  language: 'pt-br'
  value: string
  label: string
  id: string
  phoneNumber?: string | null
  groups: Group[]
}

interface PopoverAdicionarProfessorProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  groupsOptions: Option[]
  fetchTeachers: () => void
}

function PopoverAdicionarProfessor(props: PopoverAdicionarProfessorProps) {
  const { isOpen, onOpen, onClose, groupsOptions, fetchTeachers } = props
  const [groupsValue, setGroupsValue] = useState<{ id: string; value: string; label: string }[]>([])
  const { loggedUser } = useLoggedUserStore()
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(validationSchema) })
  const { mutate: createTeacherApi, isPending: isPendingTeacherCreation } = usePostApiV1Teacher({
    mutation: {
      onSuccess: () => {
        fetchTeachers()
        toast.success('Professor adicionado com sucesso.')
        reset()
        setGroupsValue([])
        setValue('phoneNumber', '')
        if (typeof onClose === 'function') {
          onClose()
        }
      },
      onError: (error) => {
        if (error?.response?.data) {
          toast.error('Verifique os dados enviados.')
        } else {
          toast.error('Erro ao adicionar professor. Verifique os dados enviados.')
        }
      },
    },
  })

  const handleAddTeacher = (data: FormValues) => {
    const groupsIds = Array.isArray(data.groups) && data.groups.length > 0 ? data.groups.map((group) => group.id) : null
    const requestData: NewTeacherViewModel = {
      schoolId: loggedUser.schoolId,
      language: 'pt-br',
      email: data.email || null,
      groups: groupsIds,
      name: data.name || null,
      phoneNumber: data.phoneNumber || null,
    }
    createTeacherApi({ data: requestData })
  }

  const handleGroupsChange = (selectedOptions: Option | Option[]) => {
    const values = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => ({
          id: option.id,
          value: option.value,
          label: option.label,
        }))
      : [
          {
            id: selectedOptions.id,
            value: selectedOptions.value,
            label: selectedOptions.label,
          },
        ]

    setGroupsValue(values)
    setValue(
      'groups',
      values.map((v) => ({ id: v.id })),
    )

    if (values.length > 0) {
      setError('groups', { type: 'manual', message: '' })
    }
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end" closeOnBlur={false}>
      <PopoverTrigger>
        <Button
          onClick={onOpen}
          variant="primary"
          className="button-detalhamento-tema mainbutton-detalhamento-tema custom-button"
          height="50px"
          borderRadius="8px">
          <img
            className="ic-add-detalhamento-tema"
            src={'/img/ic-add-1.svg'}
            alt="button-addTeacher"
            style={{ filter: 'brightness(0) invert(1)' }}
          />
          <Text hideBelow={'md'} className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px" ml={2}>
            Adicionar Professor
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
        <PopoverArrow />
        <PopoverCloseButton marginTop={'7px'} color={'brand.200'} />
        <PopoverHeader>
          <div className={'text-popover'}>Novo professor</div>
        </PopoverHeader>
        <PopoverBody marginTop={'18px'} width={'100%'}>
          <form onSubmit={handleSubmit(handleAddTeacher)}>
            <VStack gap={3} alignItems={'start'}>
              <Input
                className="input-default-style"
                placeholder={'Digite o nome do professor'}
                type="text"
                {...register('name')}
              />
              {errors.name && <Text color="red.500">{errors.name.message}</Text>}
              <Input
                className="input-default-style"
                placeholder={'Digite o email do professor'}
                type="text"
                {...register('email')}
              />
              {errors.email && <Text color="red.500">{errors.email.message}</Text>}
              <InputMask
                className="input-default-style"
                mask={inputPhoneMask}
                placeholder={'Digite o número de telefone do professor (opcional)'}
                {...register('phoneNumber')}>
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
              {errors.phoneNumber && <Text color="red.500">{errors.phoneNumber.message}</Text>}
              <Box w={'100%'} h={'100%'}>
                <MultiSelect
                  placeholder={isPendingTeacherCreation ? 'Aguarde um momento...' : 'Selecione turmas desse professor'}
                  onChange={handleGroupsChange}
                  borderColor={'gray.200'}
                  backgroundColor={'white'}
                  disabled={isPendingTeacherCreation}
                  options={groupsOptions}
                  value={groupsValue}
                  id={'turmas-teacher'}
                  w={'100%'}
                />
                {errors.groups && <Text color="red.500">{errors.groups.message}</Text>}
              </Box>
              <PopoverFooter alignSelf={'center'} w={'100%'}>
                <Button
                  variant="primary"
                  color={'white'}
                  width={'100%'}
                  height={'48px'}
                  isLoading={isPendingTeacherCreation}
                  type="submit">
                  Adicionar
                </Button>
              </PopoverFooter>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverAdicionarProfessor
