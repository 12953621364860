/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { SchoolAddressViewModel } from './schoolAddressViewModel';
import type { BasicGroupViewModel } from './basicGroupViewModel';

export interface SchoolViewModel {
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  addressId?: string | null;
  address?: SchoolAddressViewModel;
  /** @nullable */
  document?: string | null;
  admId?: string;
  deleted?: boolean;
  totalStudents?: number;
  totalTeachers?: number;
  totalGroups?: number;
  /** @nullable */
  groups?: BasicGroupViewModel[] | null;
  /** @nullable */
  thumbnail?: string | null;
  essayBalance?: number;
  essayAssistantBalance?: number;
  themeAssistantBalance?: number;
}
