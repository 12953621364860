import { EssayReview } from '@/components/EssayArea/EssayReview'
import PageSpinner from '@/components/PageSpinner'
import { getApiV1EssayEssayId } from '@/services/essay'
import { getApiV1SchoolSchoolIdInstitutionInstitutionId } from '@/services/school'
import type { CompleteEssayViewModel, EssayCompetencyViewModel } from '@/services/types'
import { ESSAY_STATUS_CONST } from '@/utils/essayStatus.js'
import { useBackNavigation } from '@/utils/useBackNavigation.ts'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, HStack, IconButton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'
import { EssayFloatingButtons } from './EssayFloatingButtons'
import { EssaySidebar } from './EssaySidebar'
import { toListOfEssayCompetencies } from './utils'

type Params = {
  essayID: string
}

const EssayGrading = () => {
  const handleBackNavigation = useBackNavigation()

  const [essay, setEssay] = useState<CompleteEssayViewModel | null>(null)
  const [competencies, setCompetencies] = useState<EssayCompetencyViewModel[]>([])

  const { essayID } = useParams() as Params
  const { loggedUser } = useLoggedUserStore()

  const isAbleToReview = !!essay?.status && essay?.status < 40
  const isEssayImage = !!essay?.attachment

  const [markerSize, setMarkerSize] = useState(25)

  useEffect(() => {
    getEssay()
  }, [])

  async function getEssay() {
    if (!essayID) return toast.error('ID da redação não encontrada')

    const essayResponse = await getApiV1EssayEssayId(essayID)
    const essay = essayResponse.data.message
    if (!essay || essay.status === ESSAY_STATUS_CONST.IN_PROGRESS) {
      toast.error('Redação não encontrada')
      handleBackNavigation()
      return
    }

    const competencies = await getCompetencies(essay)
    setCompetencies(competencies)
    setEssay(essay)
  }

  async function getCompetencies(essay: CompleteEssayViewModel) {
    const institutionId = essay.institution?.id
    const isEssayNeverReviewed = essay.competencies?.length === 0 && !!institutionId
    if (isEssayNeverReviewed) {
      const institutionResponse = await getApiV1SchoolSchoolIdInstitutionInstitutionId(loggedUser.schoolId, institutionId)
      const institution = institutionResponse.data.message
      return institution?.competencies ? toListOfEssayCompetencies(institution.competencies) : []
    }
    return essay.competencies || []
  }

  if (!essay) return <PageSpinner />

  if (!essay.id) {
    // TODO: Redirect to page 404
    return toast.error('ID da redação não encontrado')
  }

  return (
    <Page authPage={true} allowedUserType={'adm teacher monitor independentteacher'}>
      <HStack width="100%" height="100%" spacing={0}>
        <IconButton
          position="absolute"
          left={2}
          top={2}
          zIndex={1}
          onClick={handleBackNavigation}
          aria-label="Voltar"
          isRound
          icon={<ArrowBackIcon />}
          color="brand.200"
        />
        <Flex position="relative" flex={1} width={0} height="100%">
          <EssayFloatingButtons
            essay={essay}
            isImage={isEssayImage}
            isAbleToReview={isAbleToReview}
            marker={{
              size: markerSize,
              setSize: setMarkerSize,
            }}
          />
          <EssayReview
            markerSize={markerSize}
            essay={essay}
            isAbleToReview={isAbleToReview}
            isEssayImage={isEssayImage}
            competencies={competencies}
          />
        </Flex>
        <EssaySidebar
          essay={essay}
          isAbleToReview={isAbleToReview}
          competencies={competencies}
          setCompetencies={setCompetencies}
          refetchEssay={getEssay}
        />
      </HStack>
      {/*<AISupport essayId={essay.id} teacherId={essay.teacher?.id} />*/}
    </Page>
  )
}

export default EssayGrading
