/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';
import type { BalanceTransactionType } from './balanceTransactionType';
import type { BalanceTransactionSource } from './balanceTransactionSource';

export interface SchoolEssayTransactionViewModel {
  id?: string;
  school?: KeyValueViewModel;
  activity?: KeyValueViewModel;
  teacher?: KeyValueViewModel;
  type?: BalanceTransactionType;
  source?: BalanceTransactionSource;
  amount?: number;
  date?: string;
  balance?: number;
}
