/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { SchoolTeacherViewModel } from './schoolTeacherViewModel';

export interface TeacherSchoolViewModel {
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  phoneNumber?: string | null;
  /** @nullable */
  email?: string | null;
  blocked?: boolean;
  deleted?: boolean;
  /** @nullable */
  schools?: SchoolTeacherViewModel[] | null;
}
