/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-12-17 18:29:54 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface BasicThemeViewModel {
  id?: string;
  /** @nullable */
  subject?: string | null;
  /** @nullable */
  description?: string | null;
  createdAt?: string;
  amountActivities?: number;
  school?: KeyValueViewModel;
  /** @nullable */
  base64Image?: string | null;
}
